import * as procoreIframeHelpers from '@procore/procore-iframe-helpers';
import { memo } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

// This is callback component only responsible for
// notifying the parent window with access code and
// navigating back to the main page user came to
const ProcoreSuccess = (): JSX.Element => {
  // Used to grab authCode from the redirect url that Procore provides us
  const [searchParams, setSearchParams] = useSearchParams();
  const authCode: string | null = searchParams.get('code');

  const context = procoreIframeHelpers.initialize();
  context.authentication.notifySuccess({ code: authCode });
  return <></>;
};

ProcoreSuccess.displayName = 'ProcoreSuccess';
export default memo(ProcoreSuccess);
