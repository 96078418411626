import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export const ProcoreOAuth = (): JSX.Element => {
  const getSettings = async (): Promise<any> => {
    return await axios.get('/settings.json');
  };

  const { isLoading, error, data } = useQuery(['settings'], getSettings);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      const procoreAuthUrl = `https://sandbox.procore.com/oauth/authorize?response_type=code&client_id=bc23b3b6729bba86052c46bb09f5cf47ae2805e1f0bb462ffb0005145dde3ec3&redirect_uri=http://localhost:8000/mainApp`;
      location.replace(procoreAuthUrl);
      return;
    }

    if (data && data.data) {
      const response = data.data;
      const procoreAuthUrl = `${response.procoreEndpoint}/oauth/authorize?response_type=code&client_id=${response.procoreClientId}&redirect_uri=${response.procoreRedirectUri}`;
      location.replace(procoreAuthUrl);
    }
  }, [isLoading, error, data]);

  /**
   ** Render empty page and call procore oauth endpoint to get auth code
   * Followed link: https://www.npmjs.com/package/@procore/procore-iframe-helpers
   */
  return <></>;
};
