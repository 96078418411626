import { lazy } from 'react';

// Components for code splitting & lazy loading
// https://reactjs.org/docs/code-splitting.html

export const AssetUploadPage = lazy(() => import('./components/AssetUploadPage'));
export const ProjectAssetsTable = lazy(() => import('./components/ProjectAssetsTable'));
export const ProjectsPage = lazy(() => import('./components/ProjectsPage'));
export const RecordAssetPage = lazy(() => import('./components/RecordAssetPage'));
export const EditAssetsPage = lazy(() => import('./components/EditAssetsPage'));
export const DownloadHandler = lazy(() => import('./components/DownloadHandler'));
