export const BASE_ASSETS_PATH = 'projectAssets/:projectId/:projectName';
export const RECORD_ASSET_PATH = BASE_ASSETS_PATH + '/recordAsset';
export const UPLOAD_ASSET_PATH = BASE_ASSETS_PATH + '/uploadAssets';
export const DOWNLOAD_ASSETS_PATH = BASE_ASSETS_PATH + '/downloadAssets';
export const EDIT_ASSET_PATH = BASE_ASSETS_PATH + '/editAsset';
export const EDIT_ASSETS_PATH = BASE_ASSETS_PATH + '/editAssets';
export const MAIN_APP = 'mainApp';
export const MAIN_APP_AUTHORISED = 'mainAppAuthorised';
export const MAIN_APP_AUTHORIZE = 'mainAppAuthorize';
export const NAV_BAR_ID = 'AssetCollectorNav';

// Project Assets Table Constants
export const ASSET_COLLECTOR = 'Asset collector';
export const ASSET_SERVICE = 'Asset service';
export const ASSET_SOURCE_KEY = 'AssetSource';
export const ASSET_STATUS_KEY = 'AssetStatus';
export const BACKEND_VALIDATION_SERVICE = 'BACKEND_VALIDATION_SERVICE';
export const ERROR_CODES_KEY = 'ErrorCodes';
export const ERROR_KEY = 'Error';
export const LOCATION_KEY = 'Location';
export const PFHO_DATE_KEY = 'PfhoDateInEpochSeconds';
export const CREATED_BY_KEY = 'CreatedBy';
export const UPDATED_BY_KEY = 'LastUpdatedBy';
export const CREATED_ON_KEY = 'CreatedDateInEpochSeconds';
export const UPDATED_ON_KEY = 'UpdatedDateInEpochSeconds';
export const LOCATION_CODE_KEY = 'locationCode';
export const LOCATION_DESC_KEY = 'locationDescription';
export const PROPERTY_FILTER_KEY = 'propertyKey';
