/** Removes time from DateTime and persists seconds for date only.
 * @param epochSecond
 */
export const roundDateTimeToMidnightSeconds = (epochSecond: number): number => {
  return convertDateFormatToEpochSeconds(convertEpochSecondsToDateFormat(epochSecond));
};

// Format to DAY-MONTH-YEAR, ex. 02-JAN-2023
export const convertEpochSecondsToDateFormat = (dateInEpochSeconds: number): string => {
  const date = new Date(dateInEpochSeconds * 1000);
  const day = date.getUTCDate().toLocaleString('en-us', { minimumIntegerDigits: 2, useGrouping: false });
  const month = date.toLocaleDateString('en-us', { month: 'long' }).slice(0, 3).toUpperCase();
  const year = date.getUTCFullYear();
  return `${day}-${month}-${year}`;
};

const monthToNum: { [month: string]: string } = {
  JAN: '01',
  FEB: '02',
  MAR: '03',
  APR: '04',
  MAY: '05',
  JUN: '06',
  JUL: '07',
  AUG: '08',
  SEP: '09',
  OCT: '10',
  NOV: '11',
  DEC: '12',
};

export const convertDateFormatToEpochSeconds = (dateFormat: string): number => {
  const splitDate = dateFormat.split('-');
  const date = splitDate[0];
  const month = monthToNum[splitDate[1]];
  const year = splitDate[2];
  return Date.parse(`${year}-${month}-${date}T00:00:00.000Z`) / 1000;
};

export const isTenDigitString = (str: string): boolean => {
  return /^\d{10}$/.test(str);
};

export const isStringScientificNotation = (str: string): boolean => {
  return /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)$/.test(str);
};

export const isValidTagPlateId = (str: string): boolean => {
  return /^[ -~]+$/.test(str);
};

const notAvailableTagPlateIds = new Set<string>(['NO LABEL', 'NO TAG', 'N/A', 'NONE', 'NOTAVAILABLE', 'NOT AVAILABLE']);

export const isTagPlateIdNotAvailable = (str: string): boolean => {
  return notAvailableTagPlateIds.has(str);
};
