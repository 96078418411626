import { SpaceBetween } from '@amzn/awsui-components-react';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import { memo } from 'react';
import { Link } from 'react-router-dom';

export interface LoginAgainModalProps {
  visible: boolean;
}

const LoginAgainModal = (props: LoginAgainModalProps): JSX.Element => {
  return (
    <Modal
      visible={props.visible}
      closeAriaLabel="Close modal"
      size="large"
      header="Credentials expired"
    >
      <SpaceBetween
        direction="horizontal"
        size="xxs"
      >
        Please go to login page
        {<Link to="/"> here </Link>}
      </SpaceBetween>
    </Modal>
  );
};

LoginAgainModal.displayName = 'LoginAgainModal';
export default memo(LoginAgainModal);
